import React from 'react';

import { Create, SimpleForm, TextInput } from 'react-admin';

export const UseCaseCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' label='Name' align='left' />
        <TextInput source='verifierId' label='VerifierId' />
        <TextInput source='redirect.success' label='Success Url' />
        <TextInput source='redirect.failure' label='Failure Url' />
        <TextInput source='redirect.origin' label='Origin Url' />
        <TextInput source='webhook' label='Webhook Url' />
        <TextInput source='content.name' label='Content Name' />
        <TextInput source='content.title' label='Content Titel' />
        <TextInput source='content.description' label='Content Beschreibung' />
        <TextInput source='content.logoUrl' label='Content LogoUrl' />
      </SimpleForm>
    </Create>
  );